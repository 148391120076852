<template>
	<b-card
		border-variant="mmi-blue"
		header-bg-variant="mmi-blue"
		header-text-variant="white"
		class="d-flex flex-row"
	>
		<template #header>
			<h6 class="m-0 p-2 text-center" @click="toggle">LEGENDA</h6>
		</template>
		<div class="d-flex align-items-center">
			<div>
				<table class="table table-borderless m-0">
					<thead>
						<tr>
							<th>SIGLA</th>
							<th>DESCRIÇÃO</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>OEE</td>
							<td>Overall Efficiency And Effectiveness</td>
							<td>
								<info-icon id="oeeCalculation"/>
								<b-popover target="oeeCalculation" triggers="hover" placement="top">
									<template #title>Cálculo de OEE</template>
									<p>OEE% = Disponibilidade% * Performance% * Qualidade%.</p>
									<span>Disponibilidade% = (Tempo Produzindo / Tempo Disponível) * 100%.</span><br>
									<span>Performance% = ((Quantidade de Peças Boas + Quantidade de Peças Ruins) / Ordem de Produção Total) * 100%.</span><br>
									<span>Qualidade% = (1 - (Quantidade de Peças NOK / Quantidade de Peças OK)) * 100%.</span><br><br>
									<span>Ordem de Produção Total = Ordem de Produção Agendada no Calendário do Dia.</span><br>
									<span>Tempo Produzindo = Tempo Disponível – Tempo de Parada Não Programada Interna.</span><br>
									<span>Tempo Disponível = Tempo Operacional – Tempo de Parada Programada - Tempo de Parada Não Programada Externa.</span><br>
									<span>Tempo Operacional = somatório da duração dos turnos em que a máquina opera.</span>
								</b-popover>
							</td>
						</tr>
						<tr>
							<td>T/h</td>
							<td>Toneladas por hora</td>
							<td>
								<info-icon id="tHCalculation"/>
								<b-popover target="tHCalculation" triggers="hover" placement="top">
									<template #title>Cálculo de T/h</template>
									<p>T/h = (Quantidade Ciclos Prensagem Dia * FP) / Tempo Operacional.</p>
									<span>Tempo Operacional = somatório da duração dos turnos em que a máquina opera.</span><br>
									<span>FT = Fator de Produção (0,8 ou 0,6).</span>
								</b-popover>
							</td>
						</tr>
						<tr>
							<td>Prod./Plan</td>
							<td>Produzido/Planejado</td>
						</tr>
						<tr>
							<td>UR</td>
							<td>Utilization Ratio</td>
							<td>
								<info-icon id="urCalculation"/>
								<b-popover target="urCalculation" triggers="hover" placement="top">
									<template #title>Cálculo de UR</template>
									<p>UR = Tempo Produzindo no Mês / Tempo Calendário Mês.</p>
									<span>O sistema considera 24hrs sempre que:</span><br>
									<span>→ Identifica um produção.</span><br>
									<span>→ Identifica um apontamento manual ou automático.</span>
								</b-popover>
							</td>
						</tr>
						<tr>
							<td>WR</td>
							<td>Working Ratio</td>
							<td>
								<info-icon id="wrCalculation"/>
								<b-popover target="wrCalculation" triggers="hover" placement="top">
									<template #title>Cálculo de WR</template>
									<p>WR = (Tempo Produzindo / Tempo Disponível) * 100%.</p>
									<span>Tempo Produzindo = Tempo Disponível – Tempo de Parada Não Programada Interna.</span><br>
									<span>Tempo Disponível = Tempo Operacional – Tempo de Parada Programada - Tempo de Parada Não Programada Externa.</span><br>
									<span>Tempo Operacional = somatório da duração dos turnos em que a máquina opera.</span>
								</b-popover>
							</td>
						</tr>
						<tr>
							<td></td>
							<td>Qualidade</td>
							<td>
								<info-icon id="qualityCalculation"/>
								<b-popover target="qualityCalculation" triggers="hover" placement="top">
									<template #title>Cálculo de Qualidade</template>
									<span>Qualidade% = (1 - (Quantidade de Peças NOK / Quantidade de Peças OK)) * 100%.</span>
								</b-popover>
							</td>
						</tr>
						<tr>
							<td>Parada</td>
							<td>Tempo de Todas as Paradas</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</b-card>
</template>

<script>
	export default {
		data () {
			return {
				aliasPredisposto: localStorage.getItem("aliasPredisposto") || "Predisposto"
			};
		},
		methods: {
			toggle () {
				this.$emit("toggle");
			}
		}
	};
</script>

<style lang="scss" scoped>
	.card {
		width: 400px;

		.card-header {
			padding: 0;
			border: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			writing-mode: vertical-lr;
			text-orientation: upright;

			h6:hover {
				cursor: pointer;
			}
		}

		.card-body {
			padding: 0.25rem 0.75rem;
		}
	}

	.table th, .table td {
		padding: 0.25rem;
	}

	.border-mmi-blue {
		border-color: #6dcff6 !important;
	}

	.bg-mmi-blue {
		background-color: #6dcff6 !important;
	}

	.bg-dark {
		background-color: #767070 !important;
	}

	.bg-success {
		background-color: #06B558 !important;
	}

	.bg-primary {
		background-color: #406ADA !important;
	}

	.bg-warning {
		background-color: #FAAF40 !important;
	}

	.bg-danger {
		background-color: #EC1C24 !important;
	}

	#tb-estados {
		margin-left: 0.75rem;
		padding-left: 0.75rem;
		border-left: 1px solid rgba(0, 0, 0, 0.1);

		td:first-child {
			div {
				width: calc(28px - 0.25rem);
				height: calc(28px - 0.25rem);
				border-radius: 2px;
			}
		}
	}
</style>
