import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class OeeService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1/oee` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async getProducao (filtros) {
		const response = await this.axios.get("producao", { params: { filtros } });
		return response.data;
	}

	async getEficiencia (filtros) {
		const resposta = await this.axios.get("eficiencia", { params: { filtros } });
		return resposta.data;
	}

	async getWR (idMaquinas, dataTurno) {
		const resposta = await this.axios.get("wr", {
			params: { idMaquinas: idMaquinas.join(","), dataTurno }
		});
		return resposta.data;
	}

	async getOEETecnico (idMaquinas, dataTurno) {
		const resposta = await this.axios.get("oeeTecnico", {
			params: { idMaquinas: idMaquinas.join(","), dataTurno }
		});
		return resposta.data;
	}

	async getRitmo (idMaquinas, dataTurno) {
		const resposta = await this.axios.get("ritmo", {
			params: {
				aplicarFatorProducao: true,
				idMaquinas: idMaquinas.join(","),
				dataTurno
			}
		});
		return resposta.data;
	}
}
