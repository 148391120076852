<template>
	<div>
		<header class="mb-2 pt-2" :style="`margin-left: ${sideBarWidth}px;`">
			<div class="filters d-flex flex-column">
				<a href="" class="filters-handle d-inline-flex align-self-center p-2 __rounded" data-toggle="collapse" data-target=".filters-content" aria-expanded="false" aria-controls="filers">
					<div class="d-flex">
						<p class="mb-0 mr-1">Filtros</p>
						<FilterIcon />
					</div>
				</a>
				<div class="wrapper align-self-center">
					<div class="filters-content collapse mt-2">
						<div class="card tcs-card elevate-2 borderless tcs-top container py-3">
							<form @submit.prevent="search">
								<div class="form-row">
									<div class="form-group col-sm-4">
										<label for="gerencia">Gerencia</label>
										<vue-multi-select
											id="gerencia"
											disabled
											class="multi-100"
											v-model="filtros.gerenciasSelecionadas"
											:options="{ multi: true, labelName: 'nome' }"
											:selectOptions="filtros.gerencias"
											:filters="multiSelectFilters[0]"
											:btnLabel="() => filtros.gerenciasSelecionadas.map(g => g.nome).join(', ') || 'Selecione'"
										/>
									</div>
									<div class="form-group col-sm-4">
										<label for="gerencia">Áreas *</label>
										<vue-multi-select
											id="area"
											class="multi-100"
											v-model="filtros.areasSelecionadas"
											:options="{ multi: true, labelName: 'nome' }"
											:selectOptions="filtros.areas"
											:filters="multiSelectFilters[0]"
											:btnLabel="() =>
												filtros.areasSelecionadas.length > 0 ? filtros.areasSelecionadas.map(a => a.nome).join(', ')
												: (filtros.areasSelecionadas[0] && filtros.areasSelecionadas[0].nome) || 'Selecione'"
										/>
									</div>
									<div class="form-group col-sm-4">
										<label for="tipo-estado">Tipos de Estado (Status)</label>
										<vue-multi-select
											id="tipo-estado"
											class="multi-100"
											v-model="filtros.tiposEstadoSelecionados"
											:options="{ multi: true, labelName: 'nome' }"
											:selectOptions="filtros.tiposEstado"
											:filters="multiSelectFilters[1]"
											:btnLabel="() => filtros.tiposEstadoSelecionados.map(t => t.nome).join(', ') || 'Selecione'"
										/>
									</div>
									<div class="form-group col-sm-4">
										<label for="data">Data *</label>
											<datepicker
												input="date"
												placeholder="Data"
												id="data"
												input-class="bg-white"
												v-model="filtros.data"
												@selected="filtroData(filtros)"
												:format="formatter"
												:language="ptBR"
												:bootstrap-styling="true"
												:disabled-dates="{ from: maxDate }"
												data-cy="Data"
											></datepicker>
									</div>
								</div>

								<p class="small text-right mt-2 mb-0">
									Campos marcados com * são obrigatórios.
								</p>

								<div class="d-flex justify-content-end tcs-card-footer-action">
									<button
										class="btn btn-filter tcs-btn"
										type="submit"
										data-toggle="collapse"
										data-target=".filters-content"
										data-cy="Pesquisar"
										:disabled="(!filtros.data || !filtros.areasSelecionadas[0])"
									>
										<SearchIcon />
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div v-if="historicalDate || currentDate" v-bind:class="historicalDate ? 'filter-historical-date' : 'actual-date-handle'" class="filter-date __rounded">
					<p class="mb-0">{{ historicalDate || currentDate }}</p>
				</div>
				<div v-if="lastUpdate" class="last-update __rounded ml-2" title="Última Atualização">
					<p class="mb-0">
						<small>Atualizado em:</small> {{ lastUpdate }}
					</p>
				</div>
			</div>
		</header>

		<div
			class="pl-2 pr-2 d-flex flex-row flex-wrap"
			:style="`width: calc(100% - ${sideBarWidth}px); margin-left: ${sideBarWidth}px;`"
		>
			<machine-card
				class="machine-card mr-1 mb-1"
				v-for="(maquina, idx) in maquinasFiltradas()"
				:key="idx"
				:offline ="maquina.offline"
				:instalada ="maquina.instalada"
				:desativada ="maquina.desativada"
				:isOperatingNow ="maquina.isOperatingNow"
				:class="maquina.loading ? 'loading-content' : ''"
				:oldData="oldData"
				:maquina="maquina"
				:loadingLocation="maquina.loadingLocation || false"
				:location="maquina.location"
				:wr="maquina.wr"
				:oeeTecnico="maquina.oeeTecnico"
				:qualidade="maquina.qualidade"
				:chartData="maquina.chartData"
				:ultimoEventoEstado="maquina.data && maquina.data.ultimoEventoEstado"
				:duracaoParadaBruta="maquina.data && maquina.data.eventosMotivoParada && maquina.data.eventosMotivoParada.duracao_parada"
				:parada="maquina.data && maquina.data.ultimoEventoMotivoParada"
				:apontamentoAutonomo="maquina.data && maquina.data.apontamentoAutonomo"
				:produtividade="maquina.data && maquina.data.produtividade"
				:ur="maquina.data && maquina.data.ur"
				:temDadosNaDataTurno="maquina.data && maquina.data.temDadosNaDataTurno"
				:estadoAlarme="maquina.data && maquina.data.state"
				:nivelAlarme="maquina.data && maquina.data.nivel"
				:producaoRealETeorica="maquina.producaoRealETeorica"
			/>
		</div>
		<Legend
			id="legend"
			@toggle="showLegend = !showLegend"
			:style="{ right: showLegend ? '0.5rem' : '-365px' }"
		/>
	</div>
</template>

<script>
	import { mapState } from "vuex";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";
	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";

	import Legend from "@/components/DashboardArcelorMittal/Legend.vue";
	import MachineCard from "@/components/DashboardArcelorMittal/MachineCard.vue";

	import { LocalizationService } from "@/services/localization";
	import { MaquinasService } from "@/services/maquinas";
	import { OeeService } from "@/services/oee";
	import { PreferencesService } from "@/services/preferencias";
	import { AreasService } from "@/services/areas";
	import { AuthService } from "@/services/auth";
	import { AlarmesService } from "@/services/alarmes";

	const UPDATE_TIMEOUT = 4 * 60 * 1000;

	export default {
		components: {
			VueMultiSelect,
			Datepicker,
			Legend,
			MachineCard
		},

		data () {
			return {
				maquinas: [],
				showLegend: false,
				oldData: false,
				historicalDate: "",
				currentDate: "",
				lastUpdate: "",

				ptBR,
				maxDate: dayjs().endOf("day").toDate(),
				filtros: {
					data: localStorage.getItem("dataTurnoSistema"),
					tiposEstado: [
						{ id: 1, nome: "Rodando" },
						{ id: 2, nome: localStorage.getItem("aliasPredisposto") || "Predisposto" },
						{ id: 3, nome: "Parada Programada" },
						{ id: 4, nome: "Parada Não Programada" }
					],
					tiposEstadoSelecionados: [],
					gerenciasSelecionadas: [],
					gerencias: [],
					areas: [],
					areasSelecionadas: []
				},

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters()
				],

				preferences: [],

				/**
				 * Associa a id da máquina a flag de requisição de localização pendente
				 * @type {Record<number, boolean>}
				 */
				temRequisicaoPendente: {},

				oeeService: new OeeService(),
				localizationService: new LocalizationService(),
				maquinasService: new MaquinasService(),
				preferenceService: new PreferencesService(),
				areasService: new AreasService(),
				authService: new AuthService(),
				alarmesService: new AlarmesService()
			};
		},

		computed: mapState(["sideBarWidth"]),

		async mounted () {
			try {
				this.filtroData(this.filtros);

				this.preferences = await this.preferenceService.getPreferences(["AREA_MAPAS"]);

				this.filtros.areas = (await this.areasService.listAreas()) || [];
				await this.getAreasOptions();

				await this.search();
				this.updateInterval = setInterval(this.search.bind(this), UPDATE_TIMEOUT);
			} catch (error) {
				console.error(error);
				if (error.response?.status === 403)
					await this.authService.requireLogin();
			}
		},

		beforeDestroy () {
			clearInterval(this.updateInterval);
		},

		methods: {
			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			filtroData (fr) {
				if (fr.data)
					this.filtros.data = dayjs(fr.data).toDate();
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},

			maquinasFiltradas () {
				return this.maquinas.filter(m => !m.hide);
			},

			async search () {
				// Marca todas as máquinas como carregando
				for (const m of this.maquinas)
					m.loading = true;

				const dataTurnoAtual = localStorage.getItem("dataTurnoSistema");

				this.oldData = dayjs(this.filtros.data).startOf("day").isBefore(dayjs(dataTurnoAtual).startOf("day"));

				if (!this.oldData) {
					// Marca todas as máquinas como carregando localização
					for (const m of this.maquinas.filter(m => m.identificador_localizacao))
						m.loadingLocation = true;
				}

				if (this.oldData) {
					this.historicalDate = dayjs(this.filtros.data).locale("pt-br").format("D [de] MMMM [de] YYYY");
				} else {
					this.historicalDate = "";
					this.currentDate = dayjs(this.filtros.data).locale("pt-br").format("D [de] MMMM [de] YYYY");
				}

				this.$forceUpdate();

				await this.updateMachinesData();
				this.lastUpdate = dayjs().format("DD/MM/YYYY HH:mm:ss");
			},

			async getAreasOptions () {
				const areasPreferencia = ((this.preferences.find(p => p.nome === "AREA_MAPAS") || {}).valor).split(",");
				const areas = [];

				for (const idArea of areasPreferencia)
					areas.push(this.filtros.areas.find(a => a.id == idArea));

				this.filtros.areasSelecionadas = areas || [this.filtros.areas[0]];
			},

			async updatePositions () {
				const maquinasFiltradas = this.maquinasFiltradas().filter(m => m.identificador_localizacao);
				const promises = [];
				for (const m of maquinasFiltradas) {
					if (!this.temRequisicaoPendente[m.id])
						promises.push(this.updateMachineLocation(m.identificador_localizacao, m));
				}

				await Promise.all(promises);
				const positions = maquinasFiltradas.reduce((a, m) => {
					if (m.position && !a.find(p => p.lat === m.position.lat && p.lng === m.position.lng))
						a.push(m.position);

					return a;
				}, []);

				if (positions.length > 0) {
					try {
						const cities = await this.localizationService.reverseGeocode(positions);
						for (const m of maquinasFiltradas) {
							const p = cities.find(c => c.lat === m.position.lat && c.lng === m.position.lng);
							if (p) {
								m.location = {
									city: p.city,
									state: p.state
								};
							}

							// Finaliza carregamento da localização da máquina
							m.loadingLocation = false;
						}
					} catch (error) {
						console.error(error);
						if (error.response?.status === 403)
							await this.authService.requireLogin();
					}
				}

				this.$forceUpdate();
			},

			async updateMachineLocation (id, maquina) {
				try {
					this.temRequisicaoPendente[maquina.id] = true;
					const marker = await this.localizationService.getPosition(id, maquina);
					this.temRequisicaoPendente[maquina.id] = false;
					maquina.position = marker.position;
				} catch (error) {
					console.error(error);
					if (error.response?.status === 403)
						await this.authService.requireLogin();

					this.temRequisicaoPendente[maquina.id] = false;
				}
			},

			async updateMachinesData () {
				const dataTurno = dayjs(this.filtros.data).format("YYYY-MM-DD");
				const idAreas = this.filtros.areasSelecionadas.map(a => a.id);

				try {
					this.maquinas = await this.maquinasService.listMachines(null, idAreas);
					const machinesData = await this.maquinasService.getDashboardData(idAreas, dataTurno);

					// Monta array para verificação de requisições
					for (const m of this.maquinas)
						this.temRequisicaoPendente[m.id] = false;

					for (const m of this.maquinas) {
						m.data = machinesData.find(d => d.id === m.id);
						if (m.data.produtividade && m.data.produtividade !== "Infinito")
							m.data.produtividade = m.data.produtividade.toFixed(2).replace(".", ",");
						else if (m.data.produtividade === "Infinito" || m.data.produtividade == 0)
							m.data.produtividade = "0,00";

						if (m.data.ur)
							m.data.ur = m.data.ur.toFixed(2).replace(".", ",");
						else if (m.data.ur == 0)
							m.data.ur = "0,00";
					}
				} catch (error) {
					console.error(error);
					if (error.response?.status === 403)
						await this.authService.requireLogin();
				}

				// Aplica filtro de tipos de estado
				if (!this.oldData && this.filtros.tiposEstadoSelecionados.length > 0) {
					for (const m of this.maquinas) {
						let tipoEstado = m.data && m.data.ultimoEventoEstado && m.data.ultimoEventoEstado.estado && m.data.ultimoEventoEstado.estado.tipo_estado;
						tipoEstado = tipoEstado == "Predisposto" ? localStorage.getItem("aliasPredisposto") : tipoEstado;
						m.hide = !this.filtros.tiposEstadoSelecionados.find(t => t.nome === tipoEstado);
					}
				} else {
					for (const m of this.maquinas)
						m.hide = false;
				}

				// Aplica os dados que já foram carregados
				this.$forceUpdate();

				// Manda atualizar as localizações das prensas em paralelo
				let positionsPromise = Promise.resolve();
				if (!this.oldData)
					positionsPromise = this.updatePositions();

				const maquinasFiltradas = this.maquinasFiltradas();
				if (maquinasFiltradas.length > 0) {
					const idMaquinas = maquinasFiltradas.map(m => m.id);

					let producaoData = [];
					try {
						producaoData = await this.oeeService.getProducao({
							maquina: idMaquinas,
							inicio: dataTurno,
							fim: dataTurno
						});
					} catch (error) {
						console.error(error);
						if (error.response?.status === 403)
							await this.authService.requireLogin();

						producaoData = { data: [] };
					}

					for (const m of maquinasFiltradas) {
						const producaoRes = producaoData.data.find(r => r.maquina.id === m.id);
						if (!producaoRes) {
							m.producaoRealETeorica = "-";
							continue;
						}

						let producaoReal = 0;
						let producaoTeorica = 0;
						for (const valor of producaoRes.real) {
							if (valor.valores.length) {
								producaoReal = valor.valores.reduce((total, numero) => total + Number(numero.ok), 0);
							} else {
								producaoReal += Number(valor.valores);
							}
						}

						for (const valor of producaoRes.teorica)
							producaoTeorica += Number(valor.valor);

						m.producaoRealETeorica = `${producaoReal} / ${producaoTeorica}`;
					}

					// Aplica os dados que já foram carregados
					this.$forceUpdate();

					let oeeTecnicoData = [];
					try {
						oeeTecnicoData = await this.oeeService.getOEETecnico(idMaquinas, dataTurno);
					} catch (error) {
						console.error(error);
						if (error.response?.status === 403)
							await this.authService.requireLogin();

						oeeTecnicoData = [];
					}

					for (const m of maquinasFiltradas) {
						const oeeTecnicoRes = oeeTecnicoData.find(w => w.idMaquina === m.id);
						if (oeeTecnicoRes) {
							m.wr = oeeTecnicoRes.oeeTecnico.disponibilidade.toFixed(2).replace(".", ",");
							m.oeeTecnico = oeeTecnicoRes.oeeTecnico.et.toFixed(2).replace(".", ",");
							m.qualidade = oeeTecnicoRes.oeeTecnico.qualidade.toFixed(2).replace(".", ",");
							m.chartData = oeeTecnicoRes.chartData || [];
						}

						// Finaliza carregamento dos dados da máquina
						m.loading = false;
					}

					// Aplica os dados de OEE
					this.$forceUpdate();
				}

				// Garante que o carregamento das localizações terminou
				await positionsPromise;
			}
		}
	};
</script>

<style lang="scss" scoped>
	header {
		margin-top: 66px;
	}

	.machine-card {
		width: calc((100% - 1.5rem) / 6);
		min-width: calc((100% - 1.5rem) / 6);
	}

	.wrapper, #legend {
		z-index: 25;
	}

	#legend {
		position: fixed;
		transition: right 1s;
		bottom: 40px;
		box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 5px;
	}

	.filter-date, .last-update {
		margin-top: -40px !important;
	}

	@media (max-width: 1200px) {
		.machine-card {
			width: calc((100% - 1.25rem) / 5);
			min-width: calc((100% - 1.25rem) / 5);
		}
	}

	@media (max-width: 992px) {
		.machine-card {
			width: calc((100% - 1rem) / 4);
			min-width: calc((100% - 1rem) / 4);
		}
	}

	@media (max-width: 790px) {
		.card.tcs-card.tcs-top {
			border-radius: 10px !important;
		}
	}

	@media (max-width: 768px) {
		.machine-card {
			width: calc((100% - 0.75rem) / 3);
			min-width: calc((100% - 0.75rem) / 3);
		}
	}

	@media (max-width: 630px) {
		#legend {
			display: none !important;
		}
	}

	@media (max-width: 576px) {
		.machine-card {
			width: calc((100% - 0.5rem) / 2);
			min-width: calc((100% - 0.5rem) / 2);
		}
	}

	@media (max-width: 420px) {
		.machine-card {
			width: 100%;
			min-width: 100%;
		}
	}
</style>
